import { computed } from 'vue';
import { actions } from '@/store/index.js';

const user = computed(() => {
    return actions.getUser();
});

export const getPermissions = () => {
    let permissions = [];

    user.value.dashboard_users.forEach(dashboard => {
        console.log(dashboard.dashboard.name.includes('|'));

        if (dashboard.dashboard.name.includes('|')) {
            let aux = dashboard.dashboard.name.split('|');

            permissions.push(aux[1].trim());
        }
    });

    return permissions;
};

export const checkPermissions = permission => {
    let userPermissions = getPermissions();

    return userPermissions.includes(permission);
};
